* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  word-wrap: break-word;
  word-break: break-word;
  font-family: var(--font-poppins);
  font-size: var(--font-size-mini);
  transition: all 0.3s ease-in-out;
}

a {
  text-decoration: none;
}

.whiteVersion013 {
  max-width: 136px;
  width: 100%;
  height: 37px;
  object-fit: cover;
}

.frameChild {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: var(--br-3xs);
  width: 141px;
  height: 44px;
}

.signIn,
.signUp {
  font-weight: 500;
  color: var(--color-white);
  font-size: var(--font-size-lg);
  padding: 0;
  text-align: center;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.signIn {
  padding: 5px;
  background-color: var(--color-mediumslateblue);
  border-radius: var(--br-3xs);
  min-width: 141px;
  height: 44px;
  display: grid;
  place-items: center;
}

.frame1 {
  position: fixed;
  top: 0;
  /* height: 44px; */
  width: 100%;
  padding-inline: var(--padding-16xl);
  padding-block: 24px;
  z-index: 3;
  background-color: #061237;
}

.frame1 nav {
  max-width: 1280px;
  width: 100%;
  /* height: 100%; */
  margin: 0 auto;
}

.frameItem {
  border-radius: var(--br-8xs);
  width: 168px;
  height: 46px;
}

.getStarted {
  font-weight: 500;
  min-width: 168px !important;
  height: 46px;
}

.vectorParent {
  font-size: var(--font-size-mini);
  width: 100%;
  justify-content: flex-start !important;
}


.onePromptFind {
  font-size: var(--font-size-29xl);
  font-weight: 600;
  max-width: 393px;
  width: 100%;
  align-self: flex-start;
  margin-bottom: 7px;
}

.theAiPowered {
  font-weight: 500;
  font-size: var(--font-size-xl);
  margin-bottom: 17px;
}

.frameInner {
  width: 60px;
  height: 60px;
}

.howItWorks {
  font-weight: 500;
  font-size: var(--font-size-xl);
}

.groupParent {
  /* width: 199px; */
  /* height: 60px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
  border: none;
  outline: none;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
}

.webImage2b1 {
  max-width: 563px;
  width: 100%;
  height: 477px;
}

.webImage2b1 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.frame,
.frame2 {
  position: relative;
  /* overflow: hidden; */
}

.frame2 {
  width: 100%;
  font-size: var(--font-size-xl);
  // min-height: 65vh;
}

.frame {
  width: 100%;
  min-height: 690px;
  padding: var(--padding-16xl);
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../public/frame@3x.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-color: #061237;
  color: var(--color-white);
  font-family: var(--font-poppins);
  position: relative;
}

.frame__container {
  flex-direction: column;
  max-width: 1280px;
  width: 100%;
  margin: 89px auto 0;
  overflow-x: hidden;

  &:has(.featured),
  &:has(.flex__wobble),
  &:has(.why__content) {
    margin-block: 0;
  }
}

.featured_frame {
  background: #F5F7FC;
}

.featured {
  padding-block: 39px;
  width: 100%;
  text-align: center;

  p {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 39px;
  }

  .flex__featured {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    overflow: auto;
    justify-content: center;
    grid-gap: 35px;

    img {
      flex: auto;
      object-fit: contain;
      width: max-content;
      max-width: 166px;
      min-height: 34px;
      max-height: 44px;

      &:nth-last-child(1) {
        height: 44px;
      }

      &:nth-last-child(2) {
        height: 54px;
      }
    }
  }
}

.wobble__frame {

  &:has(.colored__bg) {
    background: #F5F7FC;
  }

  .flex__wobble {
    display: flex;
    width: 100%;
    align-items: center;
    grid-gap: 14px;
    padding-block: 110px 74px;


    &>* {
      flex: auto;
    }

    &.change_order {

      &>div {
        &:nth-child(2) {
          order: -1;
        }
      }
    }

    .flex__wobble__texts {
      width: 100%;
      max-width: 441px;

      div.header {
        display: flex;
        align-items: center;
        grid-gap: 8px;
        margin-bottom: 28px;

        span.line {
          width: 15px;
          height: 2px;
          display: block;
          background: #1455FE;
        }

        p {
          color: #1455FE;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 0;
        }
      }

      h3 {
        color: #000;
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 25px;
      }

      h4 {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160.355%;
        /* 28.864px */
        margin-bottom: 28px;
      }

      a {
        display: inline-flex;
        align-items: center;
        color: #1455FE;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
        outline: none !important;
        border: none !important;

        svg {
          transition: all 0.3s ease-in-out;
        }

        &:is(:hover, :focus) {
          svg {
            transform: translateX(10px);
          }
        }
      }
    }

    .flex__wobble__img {
      // width: 100%;
      // max-width: 734px;
      height: 425px;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.pro__frame {
  background: #061237;
  padding-block: 48px;
  color: #fff;

  .pro__container {
    width: 100%;
    margin: 0 auto;
    max-width: 571px;
    display: flex;
    align-items: center;
    flex-direction: column;
    grid-gap: 20px;
    text-align: center;

    h3 {
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 19;
    }
  }
}

.why__frame {
  padding-block: 126px 79px;

  .why__content {
    div.header {
      display: flex;
      align-items: center;
      grid-gap: 8px;
      margin-bottom: 25px;

      span.line {
        width: 26px;
        height: 2px;
        display: block;
        background: #1455FE;
      }

      p {
        color: #1455FE;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0;
      }
    }


    h3 {
      color: #000;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 8px;
      width: 100%;
      max-width: 441px;
    }

    h4 {
      color: #1D1D1D;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 68px;
    }

    .content__wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 80px;
      overflow: hidden;
      // flex-wrap: wrap;

      .pad {
        flex: auto;
        width: 100%;
        max-width: 342px;
        padding: 18px 15px;
        border-radius: 5px;
        border: 1px solid #E9E9E9;
        background: #FFF;
        color: #424242;


        * {
          transition: none;
        }

        .pad__content {
          display: flex;
          flex-direction: column;
          grid-gap: 17px;

          .box__content {
            width: 68px;
            height: 56px;
            display: grid;
            place-items: center;
            border-radius: 5px;
            background: #F3F3F3;
            margin-bottom: 8px;
          }

          h5 {
            color: #000;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          h6 {
            color: currentColor;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 137.023%;
            /* 21.924px */
          }
        }

        &:hover {
          color: #1455FE;
          border-color: currentColor;
          transition: all 0.2s ease-in-out;

          svg path {
            stroke: #fff;
          }

          .pad__content {
            .box__content {
              transition: background-Color 0.25s ease-in-out;
              background-color: currentColor;
            }

            h5,
            h6 {
              color: currentColor;
            }
          }
        }
      }
    }
  }
}

.review__frame {
  background: #FAFAFA;
  padding-block: 46px;

  .frame__container {
    margin-block: 0;

    .review__content {
      display: flex;
      justify-content: space-between;
      grid-gap: 55px;
      align-items: center;

      &>* {
        flex: auto;
      }

      .left__text {
        width: 100%;
        max-width: 331px;
        height: 426px;
        position: relative;
        margin-left: 24px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px 35% 5px 35%;
          position: relative;
          z-index: 1;
        }

        svg {
          position: absolute;
          left: -12px;
          top: 31px;
          z-index: 0;
        }
      }

      .right__sub {
        position: relative;
        min-height: 390px;
        overflow: hidden;

        div.controller {
          position: absolute;
          bottom: 10px;
          right: 10px;
          display: flex;
          grid-gap: 16px;
          align-items: center;

          button {
            outline: none !important;
            border: none !important;
            display: grid;
            place-items: center;
            border-radius: 3px;
            background-color: #1455FE;
            padding: 6px;
            width: 30px;
            height: 30px;

            &:is(:hover, :focus) {
              background-color: #B5C9FB;

              svg path {
                fill: #1355FD;
              }
            }
          }

        }

        div.header {
          display: flex;
          align-items: center;
          grid-gap: 8px;
          margin-bottom: 32px;

          span.line {
            width: 26px;
            height: 2px;
            display: block;
            background: #1455FE;
          }

          p {
            color: #1455FE;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 0;
          }
        }

        div.review__box {
          color: #000;

          h3 {
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 37px;
          }

          h4 {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-block: 17px 64px;
          }

          p {
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 12px;
            max-width: 80%;
          }

          span {
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            max-width: 80%;
            display: block;
          }
        }
      }
    }
  }
}

.cto__frame {
  margin-block: 55px;

  .frame__container {
    margin-block: 0;
    border-radius: 5px;
    padding: 70px 54px;
    border-radius: 5px;
    background: url('../../public/Frame.png'), linear-gradient(90deg, #1959FE 64.34%, #0047FF 140.02%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;

    .cto__content {
      display: flex;
      justify-content: space-between;
      grid-gap: 55px;
      align-items: center;

      .left__text {
        color: #fff;
        width: 100%;
        max-width: 458px;

        h3 {
          font-size: 33px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 25px;
        }

        h4 {
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 0;
        }
      }

      .right__sub {
        display: flex;
        grid-gap: 10px;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        width: 100%;
        max-width: max-content;
        padding: 5px 5px 6px 14px;
        border-radius: 3px;
        background: #FFF;

        input {
          width: 100%;
          outline: none !important;
          border: none !important;
          color: #061237;
          font-size: 17px;

          &::placeholder {
            color: #868686;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        a {
          border-radius: 5px;
          background: #061237;
          height: 100%;
          width: 117px;
          color: #FFF;
          font-size: 17px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
}

.footer__frame {
  padding-block: 31px 32px;
  background: url('../../public/footer.png'), #071337;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;

  .frame__container {
    margin-block: 0;

    .footer__content {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-bottom: 50px;
      padding-inline: 12px;
      border-bottom: 1px solid #565656;

      ul {
        display: flex;
        flex-direction: column;
        grid-gap: 40px;
        padding-left: 0;
        margin-bottom: 0;
        overflow: hidden;

        li {
          list-style-type: none;
          color: #FFF;

          h3 {
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 10px;
          }

          a {
            color: #FFF;
            font-size: 17px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            cursor: pointer;
            transition: none;

            span {
              font-size: inherit;
            }

            &:hover {
              color: #1959FE;
            }
          }
        }
      }

      &.footer__bottom {
        justify-content: center;
        border: none;
        padding: 0;

        ul {
          flex-direction: row;
          grid-gap: 30px;
          margin-bottom: 0;

          li {
            h4 {
              margin-bottom: 0;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

section {
  padding-inline: var(--padding-16xl);
}

.nav_link {

  display: flex;
  grid-gap: 30px;
  padding-left: 0;
  margin-bottom: 0;

  li {
    list-style-type: none;
    color: #FFF;

    a {
      color: #FFF;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
      transition: none;

      span {
        font-size: inherit;
      }

      &:hover {
        color: #1959FE;
      }
    }
  }

}

.panelButton {
  width: 35px;
  height: 35px;
  border: none !important;
  outline: none !important;
  border-radius: 5px;
  background-color: transparent;
  padding: 0;
  display: none;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
  }

  &:not(.noHover) {

    svg {
      width: 30px;
      height: 30px;
    }


    &:is(:hover) {
      background-color: #fff;

      svg {
        path {
          &.fill {
            fill: #0047FF;
          }

          &.stroke {
            stroke: #0047FF;
          }
        }
      }
    }
  }

}

a.activeLink {
  color: #0047FF !important;
}


/* responsive css */

@media screen and (max-width: 959px) {

  .onePromptFind {
    font-size: var(--font-size-11xl);
    /* max-width: 100%; */
  }

  section h3 {
    font-size: 20px !important;
  }

  section h5 {
    font-size: 18px !important;
  }

  section h6 {
    font-size: 14px !important;
  }

  .theAiPowered,
  section h4,
  section p {
    font-size: var(--font-size-mini) !important;
  }

  .featured_frame {
    .flex__featured {
      img {
        height: 20px !important;
      }
    }
  }

  .why__frame {
    .why__content {
      .content__wrapper {
        grid-template-columns: 1fr;
        grid-gap: 30px;

        .pad {
          max-width: 100%;
        }
      }
    }
  }

  .review__frame {
    .frame__container {
      .review__content {
        flex-wrap: wrap;
      }
    }
  }

  .cto__frame {
    .frame__container {
      .cto__content {
        flex-wrap: wrap;

        .left__text {
          max-width: 100%;
          text-align: center;
        }

        .right__sub {
          max-width: 100%;
        }
      }
    }
  }

  .frame1 {
    padding-block: 24px;

    nav {

      ul,
      ul+div {
        display: none !important;
      }

      .panelButton {
        display: flex;
      }
    }
  }
}

@media screen and (max-width: 599px) {

  section {
    padding-inline: 12px;

    &.featured_frame {
      padding-inline: 0;

      button:first-child {
        padding-left: 12px;
      }

      button:last-child {
        padding-right: 12px;
      }
    }
  }

  .frame {
    padding: 12px;
  }

  .frame1 {
    padding-inline: 12px;
  }

  .frame2 {
    flex-direction: column;
    /* text-align: center; */
  }

  .vectorParent {
    /* justify-content: center !important; */
  }

  .webImage2b1 {
    margin-top: 50px;
    max-width: 90%;
    height: auto;
    align-self: flex-start;
  }

  .howItWorks {
    font-size: var(--font-size-macro);
  }

  .frameInner {
    width: 45px;
    height: 45px;
  }

  .signIn {
    min-width: 92px;
  }

  .footer__frame {
    .frame__container {
      .footer__content {
        flex-wrap: wrap;
        grid-gap: 12px;

        ul {
          grid-gap: 20px;
          width: calc(50% - 12px);
          flex: auto;

          li {
            h3 {
              font-size: 16px !important;
            }

            a {
              font-size: 12px;
            }
          }
        }

        &.footer__bottom {
          ul {
            justify-content: center;
          }
        }
      }
    }
  }

  .wobble__frame {
    .flex__wobble {
      flex-wrap: wrap;

      .flex__wobble__texts {
        max-width: 541px;
      }

      &>div {
        &:nth-child(2) {
          order: 1 !important;
        }
      }

      // .flex__wobble__img {
      //   height: 425px;

      //   img {
      //     object-fit: contain;
      //     width: 100%;
      //     height: 100%;
      //   }
      // }
    }
  }

  .review__frame {
    .frame__container {

      .review__content {

        .left__text {
          max-width: 251px;
          height: 326px;


          svg {
            left: -20px;
            width: 100%;
            height: 294px;
          }
        }

        span {
          font-size: 14px !important;
        }

      }
    }
  }

  .cto__frame {

    .frame__container {
      padding: 32px 24px;

      .cto__content {
        grid-gap: 24px;

        .right__sub {
          height: max-content;
          flex-wrap: wrap;
          justify-content: center;
          text-align: center;

          a {
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              font-size: inherit !important;
            }
          }

          input {
            font-size: 12px;

            &::placeholder {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

}

@media screen and (max-width: 499px) {
  .footer__frame {
    .frame__container {
      .footer__content {
        // grid-gap: 50px;

        ul {
          // width: 100%;
        }

      }
    }
  }

}