@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}


h4,
h2,
h1,
h3,
h5,
h6,
p {
  margin-bottom: 0;
}

body {
  margin: 0;
  line-height: normal;

  &:not(:has(.auth_row_head)) {
    // background-color: #061237;
  }

}

:root {
  /* fonts */
  --font-poppins: Poppins;
  --font-inter: Inter;

  /* font sizes */
  --font-size-xl: 20px;
  --font-size-29xl: 48px;
  --font-size-mini: 15px;
  --font-size-macro: 13px;
  --font-size-lg: 18px;
  --font-size-4xl: 23px;
  --font-size-16xl: 35px;
  --font-size-mid: 17px;
  --font-size-11xl: 30px;
  --font-size-base: 16px;
  --font-size-3xl: 22px;
  --font-size-lgi: 19px;

  /* Colors */
  --color-white: #fff;
  --color-gray-100: #fafafa;
  --color-gray-200: #252527;
  --color-gray-300: #1d1d1d;
  --color-gray-400: #061237;
  --color-ghostwhite: #f9f7fe;
  --color-aliceblue: #edf3fe;
  --color-black: #000;
  --color-dimgray: #565656;
  --color-mediumslateblue: #1455fe;
  --color-whitesmoke-100: #f3f3f3;
  --color-whitesmoke-200: #e9e9e9;
  --color-darkslategray: #424242;
  --color-darkslateblue: #042768;

  /* Gaps */
  --gap-62xl: 81px;
  --gap-sm: 14px;

  /* Paddings */
  --padding-16xl: 35px;
  --padding-27xl: 46px;
  --padding-34xl: 53px;
  --padding-22xl: 41px;

  /* Border radiuses */
  --br-8xs: 5px;
  --br-3xs: 10px;
  --br-10xs: 3px;

  --bg-dark: #fff;
  --text-dark: #121212;
  --label-dark: #121212;
  --border1: #121212;
  --font-sm: 10px;
  --font-md: 12px;
  --font-md-lg: 14px;
  --font-lg: 16px;
  --font-h2: 18px;
  --font-h1: 20px;
  --font-header: 24px;
  --primary-color: #0950CD;
  --bg-dark13: #afafaf;
  --placeholder: #BFBDBD;
}

::-webkit-scrollbar {
  width: 10px !important;
}

::-webkit-scrollbar-track {
  background-color: #061237;
}

::-webkit-scrollbar-thumb {
  background-color: #1455fe;
  border-radius: 0;
}

.p-0 {
  padding: 0 !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.MuiTabs-scroller {
  scroll-behavior: smooth;
}

.MuiTabs-flexContainer {
  grid-gap: 35px;

  button {
    flex: auto;
    min-width: max-content;
    padding: 0;
  }
}

.align_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 15px;
}

.flex-column {
  flex-direction: column;
}

a {
  text-decoration: none;
}

// key frames and styles 
.transIn {
  animation: transIn 0.3s ease-in-out;
}

.transleft {
  animation: transleft 0.5s ease-in-out;
}

.transright {
  animation: transright 0.5s ease-in-out;
}

.transOut {
  animation: transOut 0.3s ease-in-out;
}

@keyframes transIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes transOut {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes transleft {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes transright {
  from {
    opacity: 0;
    transform: translateX(10px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.auth_row_head {
  height: 100vh;
  width: 100%;
  margin: 0 !important;
  overflow: hidden;

  &>div {
    padding: 0;
  }

  .auth_content {
    display: grid;
    place-items: center;
    height: 100%;

    &>form,
    &>div {
      width: 100%;

      header {
        // margin-bottom: 12px;

        h2 {
          color: var(--text-dark);
          font-size: var(--font-header);
          font-weight: 500;

          span {
            &.ball {
              background-color: var(--primary-color);
              width: 10px;
              height: 10px;
              display: inline-block;
              margin-left: 3px;
              border-radius: 100%;
            }
          }
        }

      }

      div:has(.input_label) {
        // margin-bottom: 23px;

        .input_label {
          text-align: left;
        }
      }

      a {
        font-size: var(--font-md-lg);
        font-weight: 400;
      }

      p {

        &.label {
          color: var(--label-dark);
          font-size: var(--font-md-lg);
          font-weight: 400;

        }

        a {
          color: var(--primary-color) !important;
          font-size: inherit !important;
          font-weight: inherit !important;
        }
      }

      h4,
      h3 {
        color: var(--text-dark);
        font-size: var(--font-h1);
        font-weight: 400;
        margin-bottom: 16px;
      }

      h3 {
        font-size: var(--font-lg);
        font-weight: 600;
        margin-bottom: 0;
      }

      .Btn {
        min-width: 189px;
        height: 56px;
        background-color: var(--primary-color);
        color: var(--bg-dark);

        font-weight: 400;
        word-break: break-word;
        border-radius: 5px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: var(--font-h1);
        }
      }

      &.auth_content__email {
        max-width: 604px;
        width: 100%;
        min-height: 7var(--font-h1);
        margin: 56px auto;
      }
    }

    .authLogo {
      display: block;
      text-align: center;
      // margin-bottom: 12px;

      svg {
        width: 86px;
        height: 86px;
      }
    }
  }

  .transright {
    overflow: auto;
    height: 100%;
  }

  .authImg {
    height: 100vh;

    .v-image__image.v-image__image--cover {
      background-image: linear-gradient(330deg, rgb(0, 0, 0) 0.55%, rgba(0, 0, 0, 0.23) 77.8%, rgba(0, 0, 0, 0.11) 89.73%, rgba(0, 0, 0, 0) 101.03%), url('../public/dimple.jpg');
      background-position: center top;
      background-size: cover;
      height: 100%;
      width: 100%;
      clip-path: polygon(0 0, 100% 0, 73% 100%, 0% 100%) !important;
    }
  }
}

// inputs 
.input_field {
  height: 50px;
  width: 100%;
  outline: none;
  border: 1px solid var(--border1);
  border-radius: 5px;
  background-color: transparent;
  font-weight: 500;
  font-size: var(--font-md);
  color: var(--text-dark);
  margin: 0px;
  padding: 7px 14px;

  &:focus,
  &.hasValue {
    border-color: var(--primary-color);
    outline: none;
  }

  &::placeholder {
    font-weight: 500;
    font-size: var(--font-md);
    color: var(--placeholder);
  }
}

// select 
.select_field {
  display: block;
  cursor: pointer;
  width: 100%;
  height: 50px;
  outline: none;
  border: 1px solid var(--border1);
  border-radius: 5px;
  font-weight: 500;
  font-size: var(--font-md);
  color: var(--text-dark);
  margin: 0px;
  padding: 7px 14px;
  background: url('../public/arrow_down.svg') 95% center no-repeat, transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  option {
    background-color: var(--bg-dark);
    color: var(--text-dark);
  }

  optgroup {
    background-color: var(--bg-dark13);

  }

  &:focus,
  &.hasValue {
    border-color: var(--primary-color);
    outline: none;
  }
}

// input label 
.input_label {
  display: block;
  font-weight: 400;
  font-size: var(--font-md);
  color: var(--label-dark);
  margin-bottom: 7px;
  text-transform: capitalize;
  max-width: max-content;
}

.spinner {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: var(--bg-dark) var(--bg-dark) transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &::after,
  &::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent var(--text-dark) var(--bg-dark13);
    width: 28px;
    height: 28px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }

  &::before {
    width: 20px;
    height: 20px;
    border-color: var(--bg-dark13) var(--text-dark) transparent transparent;
    animation: rotation 1.5s linear infinite;
  }

}

// status text design 
.errorText {
  text-align: right;
  display: block;
  font-weight: 500 !important;
  font-size: 11px;
  margin-top: 3px;
}

.slide-pane__overlay.overlay-after-open {
  z-index: 10;

  .slide-pane__header {
    display: none;
  }

  .slide-pane__content {
    height: 100%;
    padding: 22px 6px 22px 14px;
    background: #061237;
    color: #fff;

    nav,
    ul,
    ul+div.align_between {
      flex-direction: column !important;
      align-items: flex-start;
    }

    nav {
      ul {
        grid-gap: 18px;
        margin-bottom: 19px;
        width: 100%;

        li {
          width: 100%;

          a {
            display: block;
            font-size: 14px;
          }
        }
      }

      div.align_between {
        &:first-child {
          width: 100%;
          margin-bottom: 51px;

          img {
            height: 25px;
            max-width: 90px;
          }

          .panelButton {
            display: flex;
          }
        }

        a {
          font-size: 14px !important;

          &.signIn {
            max-width: 80px;
            height: 28px !important;
            padding: 0 !important;
            font-size: 12px !important;
            border-radius: 5px;

            span {
              font-size: inherit;
            }

          }

        }
      }
    }
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 767px) {
  .authImg {
    display: none !important;
  }
}